<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div v-else-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-card v-if="formData.discrepancyReportId">
      <div class="grid md:grid-cols-2 gap-4">
        <asom-form-field label="Reference No" class="col-span-2">
          <p class="font-semibold">{{formData.referenceNo}}</p>
        </asom-form-field>
        <asom-form-field label="Line">
          <p class="font-semibold">{{formData.line}}</p>
        </asom-form-field>
        <asom-form-field label="Station">
          <p class="font-semibold">{{formData.station}}</p>
        </asom-form-field>
        <asom-form-field label="Reported By">
          <p class="font-semibold">{{formData.reportedBy}}</p>
        </asom-form-field>
        <div></div>
        <asom-form-field
          label="Discrepancy Type"
          required
          :state="inputStates('formData.discrepancyTypeEnum')"
          error="Discrepancy Type required"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.discrepancyTypeEnum')"
            v-model="formData.discrepancyTypeEnum"
            :options="natureOfDiscrepancyTypes"
          />
        </asom-form-field>
        <div v-if="formData.discrepancyTypeEnum == null"></div>
        <asom-form-field
          v-else-if="formData.discrepancyTypeEnum == discrepancyOtherTypeValue"
          label="Other Reason"
          required
          :state="inputStates('formData.otherReason')"
          error="Other Reason is required"
        >
          <asom-input-text
            type="text"
            v-model="formData.otherReason"
            :state="inputStates('formData.otherReason')"
          />
        </asom-form-field>
        <asom-form-field
          v-else
          label="Transaction No"
          required
          :state="inputStates('formData.transactionNo')"
          error="Transaction number is required"
        >
          <asom-input-text
            type="text"
            v-model="formData.transactionNo"
            :state="inputStates('formData.transactionNo')"
          />
        </asom-form-field>
        <asom-form-field
          v-if="formData.discrepancyTypeEnum != null && formData.discrepancyTypeEnum != discrepancyOtherTypeValue"
          label="Available Transactions"
          class="col-span-2"
        >
          <asom-client-table
            v-if="stationTransactions.length > 0"
            :columns="[ 'no', 'transactionNo', 'dateCreated', 'action' ]"
            :data="stationTransactions"
            :pagination="true"
            :filterable="true"
          >
            <template v-slot:no="slotScoped">
              {{slotScoped.rowIndex + 1}}
            </template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{ displayUtcDate(scopedSlots.data) }}</span>
            </template>
            <template v-slot:action="scopedSlots">
              <asom-button size="sm" text="Select" @click="selectTransaction(scopedSlots.rowData)" />
            </template>
          </asom-client-table>
          <p v-else class="text-semibold">No transaction found</p>
        </asom-form-field>
        
        <asom-form-field 
          label="Description"
          required
          :state="inputStates('formData.description')"
          error="Description must be less than 1000 characters"
        >
          <asom-input-textarea
            :state="inputStates('formData.description')"
            v-model="formData.description"
          />
        </asom-form-field>
        <asom-form-field 
          label="Parties Involved"
          required
          :state="inputStates('formData.partiesInvolved')"
          error="Parties Involved must be less than 1000 characters"
        >
          <asom-input-textarea
            :state="inputStates('formData.partiesInvolved')"
            v-model="formData.partiesInvolved"
          />
        </asom-form-field>
        <asom-form-field 
          label="Action Taken"
          required
          :state="inputStates('formData.actionTaken')"
          description="Action Taken must be no less than 10 characters"
          error="Action Taken must be no less than 10 characters"
        >
          <asom-input-textarea
            :state="inputStates('formData.actionTaken')"
            v-model="formData.actionTaken"
          />
        </asom-form-field>
        <asom-form-field 
          label="Remarks"
          :state="inputStates('formData.remarks')"
          error="Remarks can only have 1000 characters max"
        >
          <asom-input-textarea
            :state="inputStates('formData.remarks')"
            v-model="formData.remarks"
          />
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          v-if="formData.attachments.length > 0"
          label="Supporting Document"
        >
          <asom-upload-file-list
            :files="formData.attachments"
            disabled-remove
          />
        </asom-form-field>
      </div>
      <div class="flex justify-end space-x-2">
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.push({ name: 'Discrepancy Reports' })"
        />
        <asom-button
          text="Submit"
          @click="submit"
          :disabled="isSubmitting"
          :is-loading="isSubmitting"
        />
      </div>
    </asom-card>
  </div>
</template>
<script>
import get from 'lodash.get';
import { required, minLength, maxLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { discrepancyNature } from "@/constants/APIEnums/cashManagementEnums";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getDiscrepancyReportDetails, editDiscrepancyReport } from '@/services/cashManagement.service';

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      error: null,
      discrepancyOtherTypeValue: discrepancyNature.OTHERS.VALUE,
      formData: {
        discrepancyReportId: null,
        referenceNo: null,
        discrepancyTypeEnum: null,
        discrepancyType: null,
        reportedBy: null,
        statusEnum: null,
        status: null,
        line: null,
        station: null,
        transactionNo: null,
        otherReason: null,
        description: null,
        partiesInvolved: null,
        actionTaken: null,
        remarks: null,
        attachments: [],
      },
    }
  },
  validations() {
    return {
      formData: {
        discrepancyReportId: {
          required,
        },
        discrepancyTypeEnum: {
          required,
        },
        actionTaken: {
          required,
          minLength: minLength(10),
        },
        remarks: {
          maxLength: maxLength(1000)
        },
        description: {
          maxLength: maxLength(1000)
        },
        partiesInvolved: {
          maxLength: maxLength(1000)
        }
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    natureOfDiscrepancyTypes() {
      var options = [];
      for (let opt of Object.values(discrepancyNature)) {
        options.push({
            value: opt.VALUE,
            label: opt.NAME,
        });
      }
      return options;
    },
    stationTransactions() {
      if (!this.formData.discrepancyTypeEnum)
        return this.$store.state.cashManagement.stationTransactions.list;
      else
        return this.$store.getters['cashManagement/stationTransactions/transactionsOfTypes'](this.formData.discrepancyTypeEnum);
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.error = null;
      const id = get(this.$route, 'params.id');
      const resp = await getDiscrepancyReportDetails(id);
      if (resp.success) {
        this.formData = {
          ...this.formData,
          ...get(resp, 'payload.data')
        };
      } else {
        this.error = resp.payload;
      }
      this.isLoading = false;
    },
    displayUtcDate,
    selectTransaction({ transactionNo }) {
      this.formData.transactionNo = transactionNo;
    },
    async submit() {
      if (this.isSubmitting) return;
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      }

      this.isSubmitting = true;
      const result = await editDiscrepancyReport({
        reportId: this.formData.discrepancyReportId,
        natureOfDiscrepancy: this.formData.discrepancyTypeEnum,
        transactionNo: this.formData.transactionNo,
        otherReason: this.formData.otherReason,
        actionTaken: this.formData.actionTaken,
        description: this.formData.description,
        involvedParties: this.formData.partiesInvolved,
        remarks: this.formData.remarks
      });
      this.isSubmitting = false;
      if (result.success) {
        this.$router.push({ name: 'Discrepancy Reports' });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    }
  }
}
</script>